import { createGlobalStyle } from "styled-components";

import "swiper/css/swiper.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/common.scss";
// import "../docs/css/flaticon.scss";

const GlobalStyle = createGlobalStyle``;

export default GlobalStyle;
