import "../App.css";
import { Fragment } from "react";
import { Helmet } from "react-helmet";

import Header from "../components/Header/Loadable";
import Hero from "../components/Hero/Loadable";
import Counter from "../components/Counter/Loadable";
import Featured from "../components/Featured/Loadable";
import Testimonial from "../components/Testimonial/Loadable";
import AboutContent from "../components/AboutContent/Loadable";
import Footer from "../components/Footer/Loadable";

import moffittLogo from "../assets/moffitt-logo-full-white.svg";

function Home() {
  return (
    <Fragment>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Header logo={moffittLogo} />

      <Hero />
      <Counter />
      <Featured />
      <AboutContent />
      <Testimonial />
      <Footer />
    </Fragment>
  );
}

export default Home;
