import "../App.css";
import { Fragment } from "react";
import { Helmet } from "react-helmet";

import Header from "../components/Header/Loadable";
import Breadcumb from "../components/Breadcumb/Loadable";
import ItemDetails from "../components/ItemDetails/Loadable";
import Footer from "../components/Footer/Loadable";

import moffittLogo from "../assets/moffitt-logo-full-white.svg";

function About() {
  const menus = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About Us",
    },
  ];

  return (
    <Fragment>
      <Helmet>
        <title>About us</title>
      </Helmet>
      <Header logo={moffittLogo} />
      <Breadcumb title="About Us" menus={menus} />
      <ItemDetails />
      <Footer />
    </Fragment>
  );
}

export default About;
